.container {
  display: flex;
  /* background-color: red; */
  flex: 1;
  align-items: center;
  padding: 40px;
}

.loginContainer {
  width: 300px;
  background-color: #eee;
  text-align: center;
  padding: 27px 18px;
  margin-bottom: 27px;
  color: black;
}

.container h1 {
  margin: 0;
  padding-bottom: 18px;
  font-size: 18px;
}

.container input,
.container button {
  padding: 9px;
  font-size: 18px;
  margin-bottom: 9px;
}

.container input.full-width {
  display: block;
  margin: 0 auto;
  margin-bottom: 9px;
  text-align: center;
}

.info {
  max-width: 21ch;
  margin: 0 auto;
  margin-bottom: 18px;
  background-color: #ddd;
  padding: 12px 24px;
  word-wrap: break-word;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 15px;
}

.info a {
  color: black;
}
